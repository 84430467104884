'use strict';

let timeoutFuncion = { timeoutID: null, element: null };
scriptSync("utils", () => {
    addEventAll("details.main-nav__dropdown",
        (e) => {
            if (e.pointerType != "touch" && e.pointerType != "") {
                e.target.setAttribute("open", "");

                if (e.target === timeoutFuncion.element) {
                    clearTimeout(timeoutFuncion.timeoutID);
                }
            }
        },
        "pointerenter", false);

    addEventAll("details.main-nav__dropdown",
        (e) => {
            if (e.pointerType != "touch" && e.pointerType != "") {
                timeoutFuncion.element = e.target;
                timeoutFuncion.timeoutID = setTimeout(() => e.target.removeAttribute("open", ""), 1000);
            }
        },
        "pointerleave", false);
});